.nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  .left{
    .nav-link{
      text-decoration: none;
      color: #fff;
      font-weight: 600;
      font-size: 25px;
    }
  }
  .right{
    display: flex;
    gap: 20px;
    align-items: center;
    .nav-link{
      text-decoration: none !important;
      color: rgba(255, 255, 255, 0.44);
      font-size: 17px;
      &.active{
        color: #fff;
      }
    }
  }
  .user-circle{
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }


}
.for-bg{
  background: #001529;

}
.info-user {
  display: flex;
  margin-bottom: 5px;

  span {
    display: inline;
    font-weight: 900 !important;
  }

  p {
    display: inline;
    margin-left: 10px;
  }
}