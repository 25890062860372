.upper-nav{
  display: flex;
  justify-content: space-between;
  .filters{
    display: flex;
    gap: 20px;
    .item{
      min-width: 200px;
      width: fit-content;
    }
  }
}
.pagination{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}